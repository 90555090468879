import { render, staticRenderFns } from "./Bank.html?vue&type=template&id=3c5066b6&scoped=true&external"
import script from "./Bank.vue?vue&type=script&lang=js"
export * from "./Bank.vue?vue&type=script&lang=js"
import style0 from "./Bank.css?vue&type=style&index=0&id=3c5066b6&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c5066b6",
  null
  
)

export default component.exports